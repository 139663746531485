import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs/Subject";
import * as moment from "moment";
import * as tzmoment from "moment-timezone";
import { fuseConfig } from "../../fuse-config";
import { isNullOrUndefined } from "util";
import { Observable, of } from "rxjs";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

@Injectable({
    providedIn: "root",
})
export class BuildingService {
    private subject;
    private subjectRadarSetup;
    public building = null;
    public hasconnector = null;
    public haspanel = null;
    public panelDoor = null;
    public panelMembers = null;
    public activeSchedules = [];
    public activeAreas = [];
    public accessLevels: any[];
    public hasAllowRadarSetup: boolean;

    constructor(private http: HttpClient, private _fuseNavigationService: FuseNavigationService) {
        this.subject = new Subject();
        this.subjectRadarSetup = new Subject();
    }

    subscribe(next, error?, complete?): any {
        return this.subject.subscribe(next, error, complete);
    }

    unsubscribe(next, error?, complete?): any {
        return this.subject.unsubscribe(next, error, complete);
    }
    selectBuilding(building): any {
        this.building = building;
        this.hasconnector = building.hasconnector;
        this.haspanel = building.haspanel;
        this.subject.next(building);
    }

    setHasAllowedRadarSetup(hasAllowedRadarSetup: boolean): void {
        this.hasAllowRadarSetup = hasAllowedRadarSetup;
        this.subjectRadarSetup.next(hasAllowedRadarSetup);
    }

    subscribeRadarSetup(next, error?, complete?): any {
        return this.subjectRadarSetup.subscribe(next, error, complete);
    }

    getUserRequests(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/requestedUsers/" + building,
            { headers: headers, withCredentials: true }
        );
    }

    approveUserRequest(building, id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                "/buildingAdmin/approveRequestedUser/" +
                building +
                "/" +
                id,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    rejectUserRequest(building, id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                "/buildingAdmin/rejectRequestedUser/" +
                building +
                "/" +
                id,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    getUserBuildings(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/userBuildings", {
            headers: headers,
            withCredentials: true,
        });
    }

    getUserBuildingById(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/userBuildings?filter=buildingid:eq:" +
                id,
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingInfo(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/buildingInfo/" + id,
            { headers: headers, withCredentials: true }
        );
    }

    getCountries(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/countries", {
            headers: headers,
            withCredentials: true,
        });
    }

    getTimezones(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/timezones", {
            headers: headers,
            withCredentials: true,
        });
    }

    getCountryStates(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/states/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    getCommunityReport(building, filter): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        let filterValues = `?filter=unit:like:${filter.unit}`;
        if (filter.rented === true || filter.renter === false) {
            filterValues += `|rented:eq:${filter.rented}`;
        }
        if (filter.resident) {
            filterValues += `&resident=${filter.resident}`;
        }
        filterValues += `&staff=${filter.staff}`;
        // if (filter.resident !== '') {
        //     filter += `|resident:in:${filter.resident}`;
        // }
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/communityReport/" +
                building +
                filterValues,
            { headers: headers, withCredentials: true }
        );
    }

    getVehicleReport(building, filter): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        let filterValues = `?filter=unit:like:${filter.unit}`;

        if (filter.guest) {
            filterValues += `|guest:like:${filter.guest}`;
        }

        if (filter.plate) {
            filterValues += `|plate:like:${filter.plate}`;
        }

        // if (filter.tag) {
        //     filterValues += `|tag:like:${filter.tag}`;
        // }

        if (filter.cardCodeFrom && filter.cardCodeTo) {
            filterValues += `|cardCode:r:${filter.cardCodeFrom}:${filter.cardCodeTo}`;
        } else if (filter.cardCodeFrom) {
            filterValues += `|cardCode:gte:${filter.cardCodeFrom}`;
        } else if (filter.cardCodeTo) {
            filterValues += `|cardCode:lte:${filter.cardCodeTo}`;
        }

        if (filter.brand) {
            filterValues += `|brand:like:${filter.brand}`;
        }
        if (filter.model) {
            filterValues += `|model:like:${filter.model}`;
        }
        if (filter.color) {
            filterValues += `|color:like:${filter.color}`;
        }
        if (filter.year) {
            filterValues += `|year:eq:${filter.year}`;
        }

        filter.issDateFrom = filter.issDateFrom
            ? moment(filter.issDateFrom).format("YYYY-MM-DD")
            : undefined;
        filter.issDateTo = filter.issDateTo
            ? moment(filter.issDateTo).format("YYYY-MM-DD")
            : undefined;

        if (filter.issDateFrom && filter.issDateTo) {
            filterValues += `|issDate:r:${filter.issDateFrom}:${filter.issDateTo}`;
        } else if (filter.issDateFrom) {
            filterValues += `|issDate:gte:${filter.issDateFrom}`;
        } else if (filter.issDateTo) {
            filterValues += `|issDate:lte:${filter.issDateTo}`;
        }

        // if (filter.resident !== '') {
        //     filter += `|resident:in:${filter.resident}`;
        // }
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/vehicleReport/" +
                building +
                filterValues +
                `&guest=${filter.guest}&resident=${filter.unit}&residentsOnly=${
                    filter.residentsOnly
                }${filter.includeLogs ? "&includeLogs=true" : ""}`,
            { headers: headers, withCredentials: true }
        );
    }

    getVehicleReportNew(building, filter): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        let filterValues = `?filter=unit:like:${filter.unit}`;
        // console.log(filter);

        if (filter.guest) {
            filterValues += `|guest:like:${filter.guest}`;
        }

        if (filter.plate) {
            filterValues += `|plate:like:${filter.plate}`;
        }

        // if (filter.tag) {
        //     filterValues += `|tag:like:${filter.tag}`;
        // }

        if (filter.cardCodeFrom && filter.cardCodeTo) {
            filterValues += `|cardCode:r:${filter.cardCodeFrom}:${filter.cardCodeTo}`;
        } else if (filter.cardCodeFrom) {
            filterValues += `|cardCode:gte:${filter.cardCodeFrom}`;
        } else if (filter.cardCodeTo) {
            filterValues += `|cardCode:lte:${filter.cardCodeTo}`;
        }

        if (filter.brand) {
            filterValues += `|brand:like:${filter.brand}`;
        }
        if (filter.model) {
            filterValues += `|model:like:${filter.model}`;
        }
        if (filter.color) {
            filterValues += `|color:like:${filter.color}`;
        }
        if (filter.year) {
            filterValues += `|year:eq:${filter.year}`;
        }
        if (filter.cardFacility) {
            filterValues += `|cardFacility:eq:${filter.cardFacility}`;
        }
        if (filter.cardCode) {
            filterValues += `|cardCode:eq:${filter.cardCode}`;
        }
        if (filter.resident) {
            filterValues += `|residentUnit:like:${filter.resident}`;
        }
        if (filter.door) {
            filterValues += `|door:eq:${filter.door}`;
        }
        if (filter.action) {
            filterValues += `|cardCode:eq:${filter.action}`;
        }
        if (filter.guest_type) {
            filterValues += `|guestType:eq:${filter.guest_type}`;
        }

        const fromHour = moment(filter.fromHour, "HH:mm");
        const toHour = moment(filter.toHour, "HH:mm");
        filter.issDateFrom =
            filter.dateType != "all" && filter.issDateFrom
                ? moment(filter.issDateFrom)
                      .set({
                          hour: fromHour.get("hour"),
                          minute: fromHour.get("minute"),
                      })
                      .format("YYYY-MM-DDTHH:mm:ss")
                : undefined;
        filter.issDateTo =
            filter.dateType != "all" && filter.issDateTo
                ? moment(filter.issDateTo)
                      .set({
                          hour: toHour.get("hour"),
                          minute: toHour.get("minute"),
                      })
                      .format("YYYY-MM-DDTHH:mm:ss")
                : undefined;

        // if (filter.issDateFrom && filter.issDateTo) {
        //     filterValues += `|issDate:r:${filter.issDateFrom}:${filter.issDateTo}`;
        if (filter.issDateFrom) {
            filterValues += `|issDate:gte:${filter.issDateFrom}`;
        }
        if (filter.issDateTo) {
            filterValues += `|issDate:lte:${filter.issDateTo}`;
        }

        // if (filter.resident !== '') {
        //     filter += `|resident:in:${filter.resident}`;
        // }
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/vehicleReport/" +
                building +
                filterValues +
                `&residentsOnly=${filter.residentsOnly}${
                    filter.includeLogs ? "&includeLogs=true" : ""
                }`,
            { headers: headers, withCredentials: true }
        );
    }

    getUnitReport(building, unit): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + `/buildingAdmin/unitReport/${building}/${unit}`,
            { headers: headers, withCredentials: true }
        );
    }

    getResidentDetails(building, resident): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        // let filterValues = `?filter=resident:like:${filter.resident}|unit:like:${filter.unit}`;
        // if (filter.rented === true || filter.renter === false) {
        //     filterValues += `|rented:eq:${filter.rented}`;
        // }
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/residentDetails/" +
                building +
                "/" +
                resident,
            { headers: headers, withCredentials: true }
        );
    }

    updateBuilding(building, body): Observable<any> {
        const data = { ...body };
        data.img =
            data.img != ""
                ? data.img.includes("data:image/png;base64")
                    ? data.img.replace("data:image/png;base64,", "")
                    : data.img.includes("data:image/jpeg;base64")
                    ? data.img.replace("data:image/jpeg;base64,", "")
                    : undefined
                : undefined;
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/buildingInfo/" + building,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    createBuildingAdmin(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/createAdmin/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateBuildingAdmin(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/updateAdmin/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    toggleBuildingAdmin(building, admin): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        // console.log(admin);
        const body = {
            email: admin.user,
            name: admin.name,
            lastname: admin.lastname,
            status: admin.status === "A" ? "S" : "A",
            admin: admin.id,
        };
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/updateAdmin/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    toggleUnitAdmin(unit, admin): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        // console.log(admin);
        const body = {
            email: admin.user,
            name: admin.name,
            lastname: admin.lastname,
            status: admin.status
                ? admin.status === "A"
                    ? "S"
                    : "A"
                : admin.status,
            admin: admin.id,
            phone: admin.phone,
        };
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/BuildingUserUnit/" + unit,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    changeAdminPassword(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/changeAdminPassword/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getGates(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/buildingGate/" + building,
            { headers: headers, withCredentials: true }
        );
    }

    getGateUsers(building, gate): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/buildingGateUsers/" +
                building +
                "/" +
                gate,
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingSecurity(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/buildingSecurityUsers/" + building,
            { headers: headers, withCredentials: true }
        );
    }

    createGate(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/buildingGate/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateGate(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/buildingGate/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    deleteGate(gate): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url + "/buildingAdmin/buildingGate/" + gate,
            { headers: headers, withCredentials: true }
        );
    }

    updateUserSecurity(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/updateSecurity/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUserSecurity(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/createSecurity/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    assignSecurityGate(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/assignSecurityGate/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    toggleBuildingSecurity(building, security): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        const body = {
            email: security.user,
            name: security.name,
            gate: security.gate,
            lastname: security.lastname,
            status: security.status === "A" ? "S" : "A",
            security: security.id,
        };
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/updateSecurity/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    changeUserPassword(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                "/buildingAdmin/changeSecurityPassword/" +
                building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getTowers(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/buildingTower/" + building,
            { headers: headers, withCredentials: true }
        );
    }

    createTowers(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/buildingTower/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateTower(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/buildingTower/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getUserUnits(building, tower, search): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/buildingUnit/" +
                building +
                "?tower=" +
                tower +
                "&search=" +
                search,
            { headers: headers, withCredentials: true }
        );
    }

    getUnitDetails(building, unit): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/buildingUnit/" +
                building +
                "/" +
                unit,
            { headers: headers, withCredentials: true }
        );
    }

    createUnit(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        if (this.building.type !== "T") {
            body.tower = "00000000-0000-0000-0000-000000000000";
        }
        body.tower = body.tower || "00000000-0000-0000-0000-000000000000";
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/buildingUnit/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateUnit(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        if (this.building.type !== "T") {
            body.tower = "00000000-0000-0000-0000-000000000000";
        }
        body.tower = body.tower || "00000000-0000-0000-0000-000000000000";
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/buildingUnit/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUserUnit(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/BuildingUserUnit/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    newOwner(data): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/resetUnit/" + data,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    // GouTru Connector

    getConnectors(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/goutru_connector",
            { headers: headers, withCredentials: true }
        );
    }

    getAlive(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/alive/" + connector,
            { headers: headers, withCredentials: true }
        );
    }

    getPanelBrands(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/control_panel_brand?filter=available:eq:true",
            { headers: headers, withCredentials: true }
        );
    }

    getControlPanels(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/control_panel",
            { headers: headers, withCredentials: true }
        );
    }

    getPanels(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/panels`,
            { headers: headers, withCredentials: true }
        );
    }

    getCameraBrands(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/camera_brand?filter=available:eq:true",
            { headers: headers, withCredentials: true }
        );
    }

    getNVRBrands(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/nvr_brand?filter=available:eq:true",
            { headers: headers, withCredentials: true }
        );
    }

    getDevices(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/device",
            { headers: headers, withCredentials: true }
        );
    }

    getDoors(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/door",
            { headers: headers, withCredentials: true }
        );
    }

    addControlPanel(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    addDevice(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(fuseConfig.url + "/buildingAdmin/device/", body, {
            headers: headers,
            withCredentials: true,
        });
    }

    getCameraSnapshot(id, channel, gtc_port): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        console.log(channel);
        return this.http.get(
            fuseConfig.url2 + `/device/${id}/photo/${channel}/${gtc_port}`,
            { headers: headers, withCredentials: true }
        );
    }

    getPhoto(device): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/device/photo/",
            { device: device },
            { headers: headers, withCredentials: true }
        );
    }

    getPreview(camera_data): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/device/preview/",
            camera_data,
            { headers: headers, withCredentials: true }
        );
    }

    syncDoors(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/door/sync",
            { headers: headers, withCredentials: true }
        );
    }

    openDoor(door): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/door/open",
            { door: door },
            { headers: headers, withCredentials: true }
        );
    }

    getLevels(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/access_level",
            { headers: headers, withCredentials: true }
        );
    }

    syncLevels(connector): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/goutru_connector/" +
                connector +
                "/access_level/sync",
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingDoors(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/door?filter=building:eq:" +
                building,
            { headers: headers, withCredentials: true }
        );
    }

    getDoorGates(door): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/door/" + door, {
            headers: headers,
            withCredentials: true,
        });
    }

    getGateDoors(gate): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/gateDoors/" + gate,
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingCameras(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/device?filter=is_nvr:eq:false|building:eq:" +
                building,
            { headers: headers, withCredentials: true }
        );
    }

    getCameraDoors(camera): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/camera/" + camera,
            { headers: headers, withCredentials: true }
        );
    }

    assignGateDoor(building, gate, door, principal): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        const body = {
            building,
            gate,
            door,
            principal,
        };
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/assignGateDoor",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    unassignGateDoor(building, gate, door): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        const body = {
            building,
            gate,
            door,
        };
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/unassignGateDoor",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    setDoorDevices(data): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/door/devices",
            data,
            { headers: headers, withCredentials: true }
        );
    }

    setConnectorBuilding(data): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/goutru_connector/building",
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updateDevice(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(fuseConfig.url + "/buildingAdmin/device/", body, {
            headers: headers,
            withCredentials: true,
        });
    }

    deleteDevice(device): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url + "/buildingAdmin/device/" + device,
            { headers: headers, withCredentials: true }
        );
    }

    updateControlPanel(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/control_panel/",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateControlPanelInfo(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/goutruAdmin/control_panel/info",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateControlPanelStatus(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/goutruAdmin/control_panel/update/status",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUpdateBuildingSettings(body): Observable<any> {
        body.event_img =
            body.event_img != ""
                ? body.event_img.includes("data:image/png;base64")
                    ? body.event_img.replace("data:image/png;base64,", "")
                    : body.event_img.includes("data:image/jpeg;base64")
                    ? body.event_img.replace("data:image/jpeg;base64,", "")
                    : undefined
                : undefined;
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/settings",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUpdateAdvancedBuildingSettings(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/settings/advanced",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUpdateKioskSmartSettings(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/kioskSmart",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createUpdateKioskSmartIPCallerSettings(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/kioskSmart/postConnect",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingSettings(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/settings/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    getSmartKioskSettings(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/kioskSmart/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    getSmartKioskStaff(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + `/buildingAdmin/kioskSmart/${id}/staff`, {
            headers: headers,
            withCredentials: true,
        });
    }


    getBuildingDealer(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/buildingAdmin/dealer/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    testMailSettings(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/settings/mail/test",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateMailSettings(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/settings/mail/update",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getBuildingNotes(building): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/getBuildingNotes/" + building,
            { headers: headers, withCredentials: true }
        );
    }

    updateBuildingNotes(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/updateBuildingNotes",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    resetControlPanel(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/reset",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    resetControlPanelTaskStatus(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/reset/status",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    syncControlPanel(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/sync",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    syncControlPanelStatus(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/sync/status",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    refreshControlPanel(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/refresh",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    refreshControlPanelStatus(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/control_panel/refresh/status",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    syncCards(data): Observable<any> {
        var body = { building: data };
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/card/sync",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getGuests(unit, status, resident): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/getUnitGuests/" +
                unit +
                "/" +
                status +
                "/" +
                resident,
            { headers: headers, withCredentials: true }
        );
    }

    getResidents(unit): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + "/buildingAdmin/getUnitResidents/" + unit,
            { headers: headers, withCredentials: true }
        );
    }

    getVehicles(unit, resident): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/getUnitVehicles/" +
                unit +
                "/" +
                resident,
            { headers: headers, withCredentials: true }
        );
    }

    getUsers(building, filter): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/admin/users/" +
                building +
                `?filter=name:like:${filter}`,
            { headers: headers, withCredentials: true }
        );
    }

    getUserResidents(user): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/admin/userResidents/" + user, {
            headers: headers,
            withCredentials: true,
        });
    }

    toggleUserStatus(email): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/admin/toggleUserStatus",
            { email },
            { headers: headers, withCredentials: true }
        );
    }

    deleteResidentUser(resident): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/admin/deleteResidentUser",
            { resident },
            { headers: headers, withCredentials: true }
        );
    }

    softDeleteResident(resident_id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url + "/buildingAdmin/softDeleteResident/" + resident_id,
            { headers: headers, withCredentials: true }
        );
    }

    //faked
    updateDealer(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        // return this.http.delete(fuseConfig.url + '/buildingAdmin/softDeleteResident/', { headers: headers, withCredentials: true });
        return of({ status: 200, message: "" });
    }

    resetDemoCommunity(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingCreator/demo",
            {},
            { headers: headers, withCredentials: true }
        );
    }
    getAreas(connector, filter?): Observable<any> {
        let f = filter ? "?filter=valid:eq:true" : "";
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/areas` +
                f,
            { headers: headers, withCredentials: true }
        );
    }

    updateArea(connector, data, area) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/areas/${area}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    createArea(connector, data) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/goutruAdmin/goutru_connector/${connector}/area`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    deleteArea(connector, area) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/area/${area}`,
            { headers: headers, withCredentials: true }
        );
    }

    getHolidays(connector, filter?) {
        let f = filter ? "?filter=valid:eq:true" : "";
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/holidays` +
                f,
            { headers: headers, withCredentials: true }
        );
    }

    syncHolidays(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/holidays/sync`,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    syncSchedules(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/schedules/sync`,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    syncAreas(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/areas/sync`,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    syncMembers(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/members/sync`,
            {},
            { headers: headers, withCredentials: true }
        );
    }

    syncAccessLevels(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/access_level/sync`,
            { headers: headers, withCredentials: true }
        );
    }

    syncPanelDoors(connector) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/door/sync`,
            { headers: headers, withCredentials: true }
        );
    }

    createHoliday(data, gtc) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/goutruAdmin/goutru_connector/${gtc}/holidays`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updateHoliday(data, gtc) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${gtc}/holidays/${data.holiday}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    deleteHoliday(connector, holiday) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/holidays/${holiday}`,
            { headers: headers, withCredentials: true }
        );
    }

    getPanelDoors(gtc) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + `/goutruAdmin/goutru_connector/${gtc}/door`,
            { headers: headers, withCredentials: true }
        );
    }

    getDoorsStatus(gtc): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + `/goutruAdmin/goutru_connector/${gtc}/door/status`,
            { headers: headers, withCredentials: true }
        );
    }

    sendDoorAction(action, door) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        const data = {
            door: door,
            action: action,
            extended: false,
        };

        if (action === "grantextend") {
            data.action = "grantaccess";
            data.extended = true;
        }

        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/goutruAdmin/door/action`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updatePanelDoor(connector, data, door) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/door/${door}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    getAccessLevel(gtc, filter?): Observable<any> {
        let f = filter ? "?filter=valid:eq:true" : "";
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${gtc}/access_level${f}`,
            { headers: headers, withCredentials: true }
        );
    }

    createAccessLevel(connector, data) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/access_level`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updateAccessLevel(connector, data, al) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/access_level/${al}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    deleteAccessLevel(connector, AL) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/access_level/${AL}`,
            { headers: headers, withCredentials: true }
        );
    }

    getSchedules(gtc, filter?) {
        let f = filter ? "?filter=valid:eq:true" : "";
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${gtc}/schedules` +
                f,
            { headers: headers, withCredentials: true }
        );
    }

    createSchedule(connector, data) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/schedules`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updateSchedule(connector, data, al) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/schedules/${al}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    deleteSchedule(connector, schedule) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/schedules/${schedule}`,
            { headers: headers, withCredentials: true }
        );
    }

    downloadImportDataTemplate(): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append(
            "Content-Type",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        return this.http.get(fuseConfig.url + `/buildingAdmin/template`, {
            headers: headers,
            withCredentials: true,
            responseType: "blob",
        });
    }

    validateImportData(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/buildingAdmin/validate`,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    importData(log): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/buildingAdmin/import`,
            { import_log: log },
            { headers: headers, withCredentials: true }
        );
    }

    importDataStatus(building, log): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/buildingAdmin/import/status/${building}/${log}?filter=cards_import:eq:false`,
            { headers: headers, withCredentials: true }
        );
    }

    cancelImportData(log): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url + `/buildingAdmin/import/${log}`,
            { headers: headers, withCredentials: true }
        );
    }

    getMembers(gtc, search?, filter?): Observable<any> {
        if (isNullOrUndefined(search)) {
            search = "";
        }

        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${gtc}/members/${search || ""}?${
                    filter || ""
                }`,
            { headers: headers, withCredentials: true }
        );
    }

    getCardsMember(gtc: any, id: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${gtc}/member/${id}/cards`,
            { headers: headers, withCredentials: true }
        );
    }

    createMember(connector, data): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/members`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    updateMember(connector, data, al) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/members/${al}`,
            data,
            { headers: headers, withCredentials: true }
        );
    }

    deleteMember(connector, schedule) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.delete(
            fuseConfig.url +
                `/goutruAdmin/goutru_connector/${connector}/members/${schedule}`,
            { headers: headers, withCredentials: true }
        );
    }

    createMemberUser(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/createMemberUser/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getEvents(gtc, filter?, object?): Observable<any> {
        if (isNullOrUndefined(filter.type)) {
            filter.type = "all";
        }

        let filterValues = "?filter=";
        //moment(row.expiration_date).tz(service.building.timezone).format('MM/DD/YYYY')
        filter.fromDate = filter.fromDate
            ? moment
                  .utc(
                      `${moment(filter.fromDate).format("YYYY-MM-DD")} ${
                          filter.fromHour
                      }`
                  )
                  .tz(this.building.timezone)
                  .format("YYYY-MM-DDTHH:mm")
            : undefined;
        filter.toDate = filter.toDate
            ? moment
                  .utc(
                      `${moment(filter.toDate).format("YYYY-MM-DD")} ${
                          filter.toHour
                      }`
                  )
                  .tz(this.building.timezone)
                  .format("YYYY-MM-DDTHH:mm")
            : undefined;

        if (filter.fromDate && filter.toDate) {
            filterValues += `|evtdate:gte:${filter.fromDate}|evtdate:lte:${filter.toDate}`;
        } else if (filter.fromDate) {
            filterValues += `|evtdate:gte:${filter.fromDate}`;
        } else if (filter.toDate) {
            filterValues += `|evtdate:lte:${filter.toDate}`;
        }

        if (filter.description) {
            filterValues += `|description:like:${filter.description}`;
        }

        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        const url =
            fuseConfig.url +
            `/goutruAdmin/goutru_connector/${gtc}/events/${filter.type || ""}${
                object ? `/${object}` : ""
            }${filterValues}`;

        headers.append("Content-Type", "application/json");
        return this.http.get(url, { headers: headers, withCredentials: true });
    }

    getUserDepartments(building, search): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/departments/" +
                building +
                "?search=" +
                search,
            { headers: headers, withCredentials: true }
        );
    }

    getDepartmentDetails(building, department): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/departments/" +
                building +
                "/" +
                department,
            { headers: headers, withCredentials: true }
        );
    }

    createDepartment(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/buildingAdmin/departments/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    updateDepartment(building, body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + "/buildingAdmin/departments/" + building,
            body,
            { headers: headers, withCredentials: true }
        );
    }

    createCardMember(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + "/goutruAdmin/cardmember",
            body,
            { headers: headers, withCredentials: true }
        );
    }

    getSpeedInfo(id_building: string, id_unit_house: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url +
                `/building/${id_building}/unit_house/${id_unit_house}/sltickets`,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postPrint(id_building: string, id_ticket: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url +
                `/building/${id_building}/sltickets/${id_ticket}/print`,
            "",
            {
                headers: headers,
                withCredentials: true,
                responseType: "arraybuffer",
            }
        );
    }

    getTicketInformation(
        id_building: string,
        id_ticket: string
    ): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + `/building/${id_building}/sltickets/${id_ticket}`,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    getTicketStatus(id_building: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(
            fuseConfig.url + `/building/${id_building}/tickets-status`,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postSlchgstatus(id_building: string, id_unit_house: string, body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/building/${id_building}/unit_house/${id_unit_house}/slchgstatus`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    getScanImage(id_building: string, id_photo: string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(
            fuseConfig.url + `/buildingAdmin/${id_building}/scanPhoto/${id_photo}`,
            {
                headers: headers,
                withCredentials: true
            }
        );
    }

    getResidentsByBuilding(building: string, resident?: string, kiosk = ""): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/buildingAdmin/residents/' + building + '?' + `${resident? `filter=id:eq:${resident}`:''}` + (kiosk ? `&kiosk=${kiosk}` : ""), {headers: headers, withCredentials: true});
    }

    getResidentsOnDirectory(building: string, resident?:string, kiosk = ""): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(
            fuseConfig.url +
                "/buildingAdmin/residents/kioskSmart/" +
                building + '?' +
                `${resident ? `filter=id:eq:${resident}` : ""}` +
                (kiosk ? `&kiosk=${kiosk}` : ""),
            { headers: headers, withCredentials: true },
        );
    }

    putSetPincodes(body: any, remove: boolean, manual?:boolean): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/buildingAdmin/residents/kioskSmart/setPincode?removeAll=${remove}&manual=${manual? manual:false}`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postResidents(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/buildingAdmin/residents/kioskSmart/`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }
    
    deleteResidents(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/buildingAdmin/residents/kioskSmart/delete`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    updateResidentOnSmartKiosk(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/buildingAdmin/residents/kioskSmart/`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    updatePhotoResidentOnSmartKiosk(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/buildingAdmin/residents/kioskSmart/photo`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    getPhotoResidents(building: string, resident:string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + `/buildingAdmin/residents/kioskSmart/photo/${resident}/${building}`, {headers: headers, withCredentials: true});
    }

    deletePhotoResidents(building: string, resident:string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.delete( fuseConfig.url + `/buildingAdmin/residents/kioskSmart/photo/${resident}/${building}`, {headers: headers, withCredentials: true});
    }


    getResidentList(building: string, status: string, filter?: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        const query = `${status ? `status=${status}` : ""}${filter ? `&filter=${filter}` : ""}`;
        return this.http.get(
            fuseConfig.url +
                `/buildingAdmin/${building}/residents?${query}`,
            { headers: headers, withCredentials: true }
        );
    }

    resendEmailMultiple(residents: { resident: string; email: string }[]): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/buildingAdmin/resendEmail/multiple`,
            {residents},
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postBuildingAdminSettingsMailUnitData(body: any): Observable<any> {
        const token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(fuseConfig.url + `/buildingAdmin/settings/mail/unitData`, body, { headers: headers, withCredentials: true, });
    }

    hideFeatureSettings() {
        const {
            adv_allow_radar_setup,
            adv_allow_smart_kiosk_setup,
            adv_allow_smart_lock_integration,
            adv_allow_panel_setup,
        } = this.building.buildingSettings;

        this._fuseNavigationService.updateNavigationItem("speed_violations", {
            hidden: !adv_allow_radar_setup,
        });
        this._fuseNavigationService.updateNavigationItem(
            "admbuild_kiosks_list",
            {
                hidden: !adv_allow_smart_kiosk_setup,
            },
        );
        this._fuseNavigationService.updateNavigationItem("smart_locks", {
            hidden: !adv_allow_smart_lock_integration,
        });

        // NOTE: Menu settings hidden by Control Panels
        this._fuseNavigationService.updateNavigationItem("admbuild_monitor", {
            hidden: !adv_allow_panel_setup,
        });
        this._fuseNavigationService.updateNavigationItem(
            "admbuild_report_access_mon",
            {
                hidden: !adv_allow_panel_setup,
            },
        );
        this._fuseNavigationService.updateNavigationItem("admbuild_tags", {
            hidden: !adv_allow_panel_setup,
        });
        this._fuseNavigationService.updateNavigationItem("control_panel", {
            hidden: !adv_allow_panel_setup,
        });
    }

    getResidentAvailableDirectories(building: string, resident: string): Observable<{directories: any}> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");

        return this.http.get(
            `${fuseConfig.url}/buildingAdmin/${building}/residents/${resident}/directories`,
            { headers: headers, withCredentials: true },
        ) as Observable<{ directories: [] }>;
    }
}